<template>
  <section>
    <widget>
      <template #header>
        <div class="d-flex my-1 mb-2 w-100 justify-content-between">
          <div class="d-flex align-items-center">
            <a class=" mr-50" @click="$router.go(-1)">
              <feather-icon icon="ChevronLeftIcon" size="20" />
            </a>
            <h3 class="m-0 font-weight-600">
              {{ translateTranslationTable(currentLocale, title) }}
            </h3>
          </div>

          <!-- Panel actions -->
          <widget-actions
            item-type="contacts"
            :show-filter="true"
            :show-list="canList"
            :is-search-needed="true"
            :show-add="canAdd"
            :size="members.length"
            :show-maximize="false"
            @updateSearch="handleChanger"
            @openModal="handleOpenModal"
            @toggleFilters="toggleFilters"
            @toggleSearchs="toggleSearchs"
          />
        </div>
      </template>
      <template>
        <div
          v-if="areFiltersVisible"
          class="flex-column flex-md-row justify-content-center filters-container mb-2 pt-1 mx-lg-2">
          <!-- Organizations -->
          <b-form-group>
            <label for="profile-organizations" class="font-weight-bold">{{ $t('filter-organization') }}</label>
            <members-select
              v-model="selectedOrganizationForFilter"
              clearable
              multiple
              searchable
              dir="ltr"
              input-id="profile-organizations"
              :placeholder="$t('form.select-placeholder')"
              :fetch-data="fetchOrganizations"
              label="name"
            />
          </b-form-group>
        </div>
      </template>
      <!-- Search -->
      <template>
        <div
          v-if="areSearchVisible"
          class=" d-flex 
          flex-column flex-md-row
          justify-content-center

        "
        >
          <b-form-group class="mx-md-2 flex-fill">
            <label for="search" class="font-weight-bold">{{ $t('search-other') }}</label>
            <b-form-input
              id="search"
              v-model="search"
              autofocus
              trim
              :placeholder="$t('form.type-placeholder')"
              :disabled="isSaving"
              @input="handleChanger"
            />
          </b-form-group>
        </div>
      </template>
      <members-list
        v-if="canList"
        :per-page="perPage"
        enable-card-size="xl"
        :members="members"
        :is-loading="isLoading"
        :total="total || 0"
        :organization-key="organizationKey"
        class="members-table"
        @total-updated="setAddOnSize($event)"
        @handleLoadOfNewItems="handleLoadOfNewItems"
        @edit="handleEditItem"
        @profile-updated="handleLoadOfNewItems(page= 1)"
      />
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="membersPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('no-permisions.message') }}
          </p>
        </b-col>
      </b-row>
    </widget>
    <b-modal
      id="contacts"
      :title="modalTitle"
      v-model="isCreateModalOpended"
      size="lg"
      no-close-on-backdrop
      footer-class="d-none"
      @hidden="currentContact = null"
    >
      <create-contacts-modal :member="currentContact" @contact-created="handleContactCreated" />
    </b-modal>
  </section>
</template>

<script>
import Widget from '@core/widgets/Widget.vue';
import MembersList from '@/views/apps/contacts/components/ContactsList.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import CreateContactsModal from '@/views/apps/contacts/components/CreateContactsModal.vue';
import { checkPermissions } from "@/@core/utils/roles-utils";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import OrganizationsAPI from "@/views/apps/organization/store/OrganizationsAPI";
import MembersSelect from "@/views/apps/member/components/MembersSelect.vue";

export default {
  name: "ContactsList",
  components: {
    MembersList,
    Widget,
    WidgetActions,
    CreateContactsModal,
    MembersSelect,
  },
  mixins: [ToastNotificationsMixin],
  props: { disableViewMore: Boolean, organizationKey: { type: String, default: null } },

  data() {
    return {
      perPage: 20,
      isLoading: false,
      page: 1,
      index: 0,
      timer: null,
      searchString: '',
      currentPage: 1,
      isCreateModalOpended: false,
      modalTitle: '',
      showfilter: false,
      areFiltersVisible: false,
      results: {},
      currentContact: null,
      selectedOrganizationForFilter: null,
      search: '',
      searchJob: '',
      searchEmail: '',
      areSearchVisible: false
    };
  },

  computed: {
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    canList() {
      return checkPermissions(
        "view",
        "contact",
        this.loggedMemberRoles,
        this.collective,
      );
    },
    canAdd() {
      return checkPermissions(
        "create",
        "contact",
        this.loggedMemberRoles,
        this.collective,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    members() {
      return this.$store.getters.contacts?.unpaginated ? this.$store.getters.contacts?.unpaginated : [];
    },
    total() {
      return this.$store.getters.contacts?.meta?.total;
    },
    widget() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).filter((item) => {
        if (item.nameSingular === 'contact') {
          return item.name;
        }
      });
      return app[0];
    },
    title() {
      return this.widget?.customizationName ? this.widget?.customizationName : this.widget?.name || this.$t('contacts.page-title.contacts-list');
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  watch: {
    selectedOrganizationForFilter() {
      this.fetchData({ page: 1, searchString: '', forceAPICall: true });
    }
  },
  async created() {
    await this.fetchData({ page: this.currentPage, searchString: '', forceAPICall: true });
  },

  methods: {
    fetchOrganizations(page, searchString = "") {
      return OrganizationsAPI.fetchPage({
        communityKey:
          this.$store.getters.mainCollective?.key ||
          this.$store.getters.currentCollective.key,
        listingType: 0,
        searchString: searchString || "",
        typeKey: null,
        page,
        perPage: 16,
      });
    },
    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
    },
    toggleSearchs(response) {
      if (response === true) {
        this.areSearchVisible = !this.areSearchVisible;
      }
    },
    async fetchData({ page, searchString = '', forceAPICall = false }) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "contacts",
        page,
        forceAPICall,
        perPage: this.perPage,
        requestConfig: {
          ...(searchString != '' ? { searchString } : ''),
          ...(searchString != '' ? { searchEmail: searchString } : ''),
          ...(searchString != '' ? { searchJob: searchString } : ''),
          ...(this.areFiltersVisible ? { organizationKeys: this.selectedOrganizationForFilter.map(({ key }) => key) } : ''),
        },
      });
      this.results = this.members.map(item=>item);
      this.isLoading = false;
    },
    async handleLoadOfNewItems({ page }) {
      if (!this.isLoading && this.members.length <= this.total) {
        await this.fetchData({ page, searchString: this.searchString, forceAPICall: true });
      }
    },
    searchItems(value) {
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.members.filter(
        (item) => item.name.toLowerCase().includes(searchTerm)
        || item.surname.toLowerCase().includes(searchTerm),
      );
    },
    handleOpenModal() {
      this.isCreateModalOpended = true;
      this.modalTitle = this.$t('contacts.create-contact');
    },
    async handleContactCreated() {
      this.isCreateModalOpended = false;
      this.currentContact = null;
      await this.fetchData({ page: this.currentPage, searchString: '', forceAPICall: true });
    },
    handleChanger(search, page = {page: 1}) {
      this.currentPage = page.page;
       if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.fetchData({ page: this.currentPage, searchString: search, forceAPICall: true });
    },800);
    },
    translateTranslationTable,
    handleEditItem(item) {
      this.currentContact = item;
      this.modalTitle = this.$t('contacts.edit-title');
      this.isCreateModalOpended = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.members-table {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex: 1;
  padding-bottom: 20px;
}
</style>
